import axios from 'axios';
import chroma from 'chroma-js';
import { Logger } from 'seq-logging';
import { getAvailableRoutes} from './RouteUtils';

const API_KEY='B3UnAYQeUuetmQOwU48vmQ==';

const mutedTextColor = "#5c6873";
const colorStylesForSelectElem = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma(mutedTextColor);
      return {
        ...styles,
        backgroundColor: isSelected
          ? mutedTextColor
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : mutedTextColor,  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? mutedTextColor : color.alpha(0.3).css()),
        },
      };
    },
    singleValue: styles => {
        return { 
            ...styles,
            color: mutedTextColor
        }
    }
}

const loginModes = {
  Credential: "Credentials",
  IDCard: "IDCard"
};

function appendAPIKeyToURL(url){
  url.includes('?') ? url += '&' : url += '?';
  return url + 'api_key=' + API_KEY;
}

function licenseContainsBDE(){
  var licensedModules = JSON.parse(localStorage.getItem('licensedModules'));
  if(licensedModules){
      return licensedModules.BDE.BDEBasic && licensedModules.WebClient.BDE;
  }
  return false
}

function licenseContainsPZEWorkflow(){
  var licensedModules = JSON.parse(localStorage.getItem('licensedModules'));
  if(licensedModules){
    return licensedModules.PZE.PZEWorkflow;
  }
  return false;
}

function terminalModeIsActive(){
  const terminalModeEntry = localStorage.getItem('TerminalModeActive');
  return terminalModeEntry ? JSON.parse(terminalModeEntry): false;
}

function staffHasRequestSubmitPermission(webClientRolePermissions){
  return webClientRolePermissions.Requests.SubmitRequests;
}

function staffHasRequestEditPermission(webClientRolePermissions){
  return webClientRolePermissions.Requests.EditRequests;
}

function workflowForBDEIsEnabled(){
  const webClientConfiguration = JSON.parse(localStorage.getItem('WebClientConfiguration'));

  return webClientConfiguration && webClientConfiguration.BDE && webClientConfiguration.BDE.WorkingMode;
}

function workflowForBDEIsUsed(){
  const webClientWorkingMode = localStorage.getItem('WorkingMode');

  return webClientWorkingMode && webClientWorkingMode === "Workflow";
}

function getProductName(){
    var name = "PZE ";
    if(licenseContainsBDE() === true){
        name = "PZBDE " 
    }
    return name;
}

function getFullProductName(){
    var name = "PZE ";
    if(licenseContainsBDE() === true){
        name = "PZBDE " 
    }
    name += "WebClient 3.0"
    return name;
}

function getUserStartPage(userInfo = JSON.parse(localStorage.getItem('userInfo'))){
  const webClientConfiguration = JSON.parse(localStorage.getItem('WebClientConfiguration'));

  let startPage = "";
  if (userInfo && !userInfo.UserConfiguration.DefaultStartPageUsage){
    startPage = userInfo.UserConfiguration.StartPage;
      
    // set up start page is not in availablePages, then use the defaultStartPage for the TerminalMode
    const availablePaths = getAvailableRoutes().map( route => route.path.slice(1));
    if(!terminalModeIsActive() && !availablePaths.includes(startPage)){
      startPage = webClientConfiguration.PZE.DefaultStartPage;
    }
  }
  else {
    if(webClientConfiguration){
      startPage = webClientConfiguration.PZE.DefaultStartPage;
    }
  }
  
  return startPage.length > 0 ? startPage.charAt(0).toLowerCase() + startPage.slice(1): "";
}

function redirectUserToStartPage(props){
  const startPage = getUserStartPage();

  let redirectTo ='/' + startPage;
  if(props.location.state && props.location.state.from.pathname !== "/"){
    redirectTo = props.location.state.from.pathname;  
  }
  
  props.history.push(redirectTo);
}

function getUserClientInterval(){
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let clientLogoutTimeSpan = "";
  if (userInfo && !userInfo.UserConfiguration.DefaultClientAutoLogoutUsage){
    clientLogoutTimeSpan = userInfo.UserConfiguration.ClientAutoLogout;
  }
  else{
    const webClientConfiguration = JSON.parse(localStorage.getItem('WebClientConfiguration'));
    if(webClientConfiguration){
      clientLogoutTimeSpan = webClientConfiguration.PZE.DefaultClientAutoLogout;
    }
  }

  return clientLogoutTimeSpan;
}

async function getWebClientConfiguration(){
  const url = window['runConfig'].RESTAPI_URL_PZE + 'WebClientConfiguration/WebClientConfiguration';
  
  try{      
    const response = await axios.get(appendAPIKeyToURL(url)).then( (res) =>{
      return res;
    });
    if(response.status === 200){
      const config = response.data;
      localStorage.setItem('WebClientConfiguration', JSON.stringify(config));
    }  
  }
  catch(error) {
    console.log("Error in WebClientConfiguration/WebClientConfiguration: ", error.message);      
  }
  
}

async function callUserLoginState(){
  let userIsLoggedIn = null;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if(userInfo){
    const url = window['runConfig'].RESTAPI_URL_PZE + "UserAuthentication/SetUserAlive";

    const requestData = {
      StaffID: userInfo.StaffID
    }

    try{
      const response = await axios.post(appendAPIKeyToURL(url), requestData).then( (res) =>{
        return res;
      });
      if(response.status === 200){
        userIsLoggedIn = response.data.userIsLoggedIn;
        localStorage.setItem('userIsLoggedIn', JSON.stringify(userIsLoggedIn));
      }
    }
    catch(error){
      if(error.request){ // timeout reached
        console.log("Timeout")
      }
    }
  }
  return userIsLoggedIn;
}

async function getNumberOfUnreadRequests(){
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let numberOfUnreadRequests = null;

  if(userInfo){
    const url = window['runConfig'].RESTAPI_URL_PZE + 'WagePaymentRequest/GetNumberOfUnreadRequestsByStaffWmdID?staffWmdID=' + userInfo.StaffWorkingModelID.toString();
  
    try{      
      const response = await axios.get(appendAPIKeyToURL(url)).then((res)=>{
        return res;
      });
      if(response.status === 200 ){
        numberOfUnreadRequests = response.data["TotalNumberOfUnreadRequests"];
      }
    }
    catch(error) {
      console.log("Error in WagePaymentRequest/GetNumberOfUnreadRequestsByStaffWmdID: ", error.message);
    }
    finally{
      return numberOfUnreadRequests;
    }
  }
  else{
    return numberOfUnreadRequests;
  }
  
}

function getEntryPageBasedOnMode(){
  return workflowForBDEIsEnabled() && workflowForBDEIsUsed() ? "/workplaceOverview" : "/login";
}

function pushToEntryPage(history){
  history.push(getEntryPageBasedOnMode());
}

function handleStorageElementsForLogout() {
    localStorage.setItem("userState", "inactive");
    
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userStaffs");
    localStorage.removeItem("loginMode");
    localStorage.removeItem("TimeTypes");
    localStorage.removeItem("TimeTypes_IDs");
    //localStorage.removeItem("TimeTypesForAbsenceWorkflow");
    localStorage.removeItem("FlextimeAccount");
    localStorage.removeItem("ValidTimeAccounts");
    localStorage.removeItem("PayoutTimeAccounts");
    localStorage.removeItem("MonthAccountingInfo");
    localStorage.removeItem("TimeTypesForAbsenceCalendar");
    localStorage.removeItem("TimeTypes_ActivityEvents");
    localStorage.removeItem("componentPath");
    localStorage.removeItem("pwdAlreadyChanged");
    localStorage.removeItem("userIsLoggedIn");
    //localStorage.removeItem('unadoptedWebBookingsForStaffWmdID'); // TODO ?
    localStorage.removeItem('latestDbBookingForStaffWmdID');
    localStorage.removeItem('VacationData');
    localStorage.removeItem('HolidayBookingsUnit');
    localStorage.removeItem('lastActive');

    localStorage.removeItem('UnloadTime');
    localStorage.removeItem('logoutRequired');

    if(licenseContainsBDE()){
      localStorage.removeItem("WorkOrders");
      localStorage.removeItem("ValidActivities");
      localStorage.removeItem("StatusesForSequences");
      localStorage.removeItem("Quantities");
      //localStorage.removeItem("latestEventOrderBookingForStaffWmdID"); 
      localStorage.removeItem("ValidWorkplaces");    
      if(localStorage.getItem("WorkingMode") && localStorage.getItem("WorkingMode") === "Workflow"){
        localStorage.removeItem("selectedActivityForBookings");
        localStorage.removeItem("selectedWorkOrderForBookings");
        localStorage.removeItem("selectedBookingLevelForBookings");
        localStorage.removeItem("workOrderStructure");
        localStorage.removeItem("StatusForPausing");
        localStorage.removeItem("activeTab");       
      }
    }
}

function handleSEQLogging(seqLogger){
  if(window['runConfig'].SEQ_URL)
  {
    seqLogger = new Logger({serverUrl: window['runConfig'].SEQ_URL, apiKey: window['runConfig'].SEQ_KEY})

    function GetLogMessage(message, params)
    {
      var text = message?.toString();

      try
      {
        if(params)
        {
          if (typeof params === 'string')
          { 
            text += params.toString();
          }else
          {
            text += JSON.stringify(params)
          }
        }
      } catch(error)
      {
        // Just catch it, we do not want an error during logging
      }

        return text;
    }

    var realConsoleLogHandler = console.log;
    console.log = (message, params) => 
    {
      realConsoleLogHandler(message, params);
      
      if(!message.startsWith("i18next"))
      {
        seqLogger.emit({level: "Verbose", messageTemplate: GetLogMessage(message, params), properties: params});
      }
    }

    var realConsoleInfoHandler = console.info;
    console.info = (message, params) => 
    {
      realConsoleInfoHandler(message, params);
      seqLogger.emit({level: "Information", messageTemplate: GetLogMessage(message, params), properties: params});
    }
    
    var realConsoleErrorHandler = console.error;
    console.error = (message, params) => 
    {
      realConsoleErrorHandler(message, params);
      seqLogger.emit({level: "Error", messageTemplate: GetLogMessage(message, params), properties: params});
    }

    var realConsoleDebugHandler = console.debug;
    console.debug = (message, params) => 
    {
      realConsoleDebugHandler(message, params);
      seqLogger.emit({level: "Debug", messageTemplate: GetLogMessage(message, params), properties: params});
    }

    // Log web requests with an error
    axios.interceptors.response.use(undefined, error => {
    
      var url = new URL(error?.config?.url ?? "http://no.url")
      var params = new URLSearchParams(url.search);
      params.set('api_key', '***');
      params.set('password', '***')
      url.search = params.toString();

      seqLogger.emit({level: "Error", messageTemplate: '{Message} on request to {ErrorUrl}', properties:{
        Message: error.message,
        ErrorUrl: url
      }});

      return Promise.reject(error);
    });
  }
}

export { 
    appendAPIKeyToURL,
    colorStylesForSelectElem,
    loginModes,
    licenseContainsBDE,
    licenseContainsPZEWorkflow,
    terminalModeIsActive,
    staffHasRequestSubmitPermission,
    staffHasRequestEditPermission,
    workflowForBDEIsEnabled,
    workflowForBDEIsUsed,
    getProductName,
    getFullProductName,
    getUserStartPage,
    redirectUserToStartPage,
    getUserClientInterval,
    getWebClientConfiguration,
    callUserLoginState,
    getNumberOfUnreadRequests,
    getEntryPageBasedOnMode,
    pushToEntryPage,
    handleStorageElementsForLogout,
    handleSEQLogging
}