import React, { Component } from 'react';
import { 
  BrowserRouter, 
  Route,
  Switch
 } from 'react-router-dom';
//import logo from './logo.svg';
import './App.scss';
import { 
  PrivateRoute, 
  EntryRoute
} from './utils/RouteUtils';
import LoadingModal from './utils/LoadingModal';

import { callUserLoginState, appendAPIKeyToURL, getWebClientConfiguration, handleSEQLogging } from './utils/CommonUtils';
import { 
  getOpenComponents,
  getClosedComponents,
  getNewComponentNameInLocalStorage,
  updateComponentEntryInLocalStorage,
  removeClosedComponentsFromStorage,
  getComponentsFromLocalStorage,
  getLatestClosingTime
} from './utils/StorageUtils';
import { loading } from './utils/Spinners';

import i18n from './i18n';
import axios from 'axios';
import moment from 'moment';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const WorkflowLayout = React.lazy(() => import('./containers/WorkflowLayout/WorkflowLayout'));

var seqLogger = null;

class App extends Component {
  constructor(props) {
    super(props);

    // Log to SEQ if SEQ_URL is present
    handleSEQLogging(seqLogger)

    this.handleLoad = this.handleLoad.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    
    this.updateLoginState = this.updateLoginState.bind(this);
    this.updateClientLogoutInterval = this.updateClientLogoutInterval.bind(this);
    const detectedLang = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng"): navigator.language;
    const compName = getNewComponentNameInLocalStorage();

    this.getClientLogoutInterval = this.getClientLogoutInterval.bind(this);
    this.setBDEWorkflowParameters = this.setBDEWorkflowParameters.bind(this);
    
    this.setTerminalModeParameters = this.setTerminalModeParameters.bind(this);

    let defaultRequestTimeout = 10_000;
    let desiredRequestTimeout = window['runConfig'].REQUEST_TIMEOUT;
    if(desiredRequestTimeout !== undefined && Number.isInteger(desiredRequestTimeout) && desiredRequestTimeout >= 0){
      defaultRequestTimeout = window['runConfig'].REQUEST_TIMEOUT;
    }

    // Set configuration value to ensure global availability
    window['runConfig'].REQUEST_TIMEOUT = defaultRequestTimeout;

    // Set default timeout for all axios requests. Per instance or request timeouts have precedence
    axios.defaults.timeout = defaultRequestTimeout;

    this.state = {
      selectedLang: detectedLang,
      userIsLoggedIn: null,
      componentName: compName,

      clientLogoutInterval: "",

      BDEWorkflowModuleIsEnabled: false,
      WorkingMode: null,
      available: false,

      isTerminalModeActive: false
    };
  }

  async componentDidMount(){
    window.addEventListener("load", () => this.handleLoad());
    window.addEventListener("unload", () => this.handleUnload());

    await this.getLicenseInformation();
    await getWebClientConfiguration();
    this.getClientLogoutInterval();
    this.setBDEWorkflowParameters();
    this.setTerminalModeParameters();
  }

  componentWillUnmount(){
    window.removeEventListener("load", this.handleLoad);
    window.removeEventListener("unload", this.handleUnload);
  }

  componentDidCatch(error, errorInfo) {
    if(seqLogger){
      seqLogger.emit({level: "Error", messageTemplate: error.toString(), properties: null});
    }
  }

  async handleLoad(){
    const allComponents = getComponentsFromLocalStorage();
    const openedComponents = getOpenComponents();
    let closedComponents = getClosedComponents();

    const userState = localStorage.getItem('userState');
    
    const loadTime = moment();
    
    if(allComponents !== null){
      const latestUnloadingTime = getLatestClosingTime(closedComponents);
      
      removeClosedComponentsFromStorage(closedComponents)
      // Get new list of now closed tabs
      closedComponents = getClosedComponents();

      if(openedComponents.length === 0 && closedComponents.length === 0 && userState === "active"){
        const timeDiff = loadTime.diff(latestUnloadingTime);
        
        if(timeDiff > 650 && !window.performance.getEntriesByType("navigation").map(nav => nav.type).includes('reload')){
          localStorage.setItem("logoutRequired", JSON.stringify(true));
        }
      } 
      
      localStorage.removeItem('UnloadTime');
    }
    
    updateComponentEntryInLocalStorage(this.state.componentName, false)   
  }

  handleUnload(){
    updateComponentEntryInLocalStorage(this.state.componentName, true)
  }

  changeLang(lng){
    i18n.changeLanguage(lng);
    this.setState({
      selectedLang: lng
    });
  };

  async updateLoginState(){
    const userIsLoggedIn = await callUserLoginState();
    this.setState({
      userIsLoggedIn: userIsLoggedIn
    });
  }

  async getLicenseInformation(){
    let url = window['runConfig'].RESTAPI_URL_PZE + 'License/GetLicenseInformationForWebClient';
    
    try{      
      const response = await axios.get(appendAPIKeyToURL(url)).then( (res) =>{
        return res;
      });
      if(response.status === 200){
        const modules = response.data["licensedModules"];  
        localStorage.setItem('licensedModules', JSON.stringify(modules));
      }  
    }
    catch(error) {
      console.log("Error in License/GetLicenseInformation: ", error.message);      
    }
  
  }

  getClientLogoutInterval(){
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let clientAutoLogout = "";
    if (userInfo && !userInfo.UserConfiguration.DefaultClientAutoLogoutUsage){
      clientAutoLogout = userInfo.UserConfiguration.ClientAutoLogout;
    }
    else{
      if(JSON.parse(localStorage.getItem('WebClientConfiguration'))){
        clientAutoLogout = JSON.parse(localStorage.getItem('WebClientConfiguration')).PZE.DefaultClientAutoLogout;
      }
    }

    this.setState({
      clientLogoutInterval: clientAutoLogout
    })
  }

  setBDEWorkflowParameters(){
    const webClientConfiguration = JSON.parse(localStorage.getItem('WebClientConfiguration'));
    if (webClientConfiguration && webClientConfiguration.BDE && webClientConfiguration.BDE.WorkingMode) {// BDE Workflow license
      
      // If WorkingMode is already set in local storage, then use this value and do not use the configuration value.
      const workingModeSettingFromStorage = localStorage.getItem('WorkingMode');
      if(!workingModeSettingFromStorage){
        localStorage.setItem('WorkingMode', webClientConfiguration.BDE.WorkingMode);
      }

      this.setState({
        available: true,
        BDEWorkflowModuleIsEnabled: true,
        WorkingMode: workingModeSettingFromStorage ? workingModeSettingFromStorage : webClientConfiguration.BDE.WorkingMode
      });
    }
    else {// no BDE Workflow license
      this.setState({
        available: true
      })
    }
  }

  changeWorkingMode(newMode, history){
    const newModeValue = newMode.target.value;
    if(newModeValue !== this.state.WorkingMode){
      this.setState({
        WorkingMode: newModeValue
      })
      localStorage.setItem('WorkingMode', newModeValue);
    }

    switch(newModeValue){
      case "Standard":
        localStorage.removeItem('activitiesForWorkplace'+ JSON.parse(localStorage.getItem("selectedWorkplaceID")));
        localStorage.removeItem('ValidWorkplaces');
        localStorage.removeItem('selectedWorkplaceID');
        break;

      case "Workflow":
        history.push("/workplaceOverview")
        break;

      default:
        break;
    }
  }

  setTerminalModeParameters(){
    const webClientConfiguration = JSON.parse(localStorage.getItem('WebClientConfiguration'));
    if (webClientConfiguration && webClientConfiguration.PZE) {
      
      // If TerminalMode is already set in local storage, then use this value and do not use the configuration value.
      const terminalModeSettingFromStorage = localStorage.getItem('TerminalModeActive');
      if(!terminalModeSettingFromStorage){
        localStorage.setItem('TerminalModeActive', webClientConfiguration.PZE.TerminalMode);
      }

      this.setState({
        isTerminalModeActive: terminalModeSettingFromStorage ? JSON.parse(terminalModeSettingFromStorage) : webClientConfiguration.PZE.TerminalMode
      });
    }
  }

  changeTerminalModeState(){
    const newActiveState = !this.state.isTerminalModeActive;
    this.setState({
      isTerminalModeActive: newActiveState
    });

    localStorage.setItem('TerminalModeActive', newActiveState);
  }

  updateClientLogoutInterval(){
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.setState({
      clientLogoutInterval: userInfo.UserConfiguration.ClientAutoLogout
    })
  }

  render() {
    //const { t, i18n } = this.props;
    const actualLanguage = this.state.selectedLang; 

    if(!this.state.available){
      return(
        <LoadingModal
          open={!this.state.available}
          msgKey="common:loadData"
        />
      )
    }
    else{
      if(this.state.BDEWorkflowModuleIsEnabled && this.state.WorkingMode && this.state.WorkingMode === "Workflow"){// starting point pased on WorkingMode
        return(
          <BrowserRouter basename="/pzeweb">
            <React.Suspense fallback={loading()}>
              <Switch>
                <EntryRoute 
                  exact 
                  path="/workplaceOverview"
                  render={props =>
                    <WorkflowLayout
                      lang={actualLanguage} 
                      onChangeLang={(lng) => this.changeLang(lng)} 
                      workingMode={this.state.WorkingMode}
                      onChangeWorkingMode={(mode, history) => this.changeWorkingMode(mode, history) }
                      {...props}
                    />
                  }
                />
                <Route 
                  exact
                  path="/login" 
                  name="Login"
                  render={props => 
                    <Login 
                      onChangeClientLogoutInterval={() => this.updateClientLogoutInterval()} 
                      {...props} 
                    />
                  } 
                />
                
                <PrivateRoute 
                  path="/" 
                  name="PZBDE WebClient 3.0" 
                  userIsLoggedIn={this.state.userIsLoggedIn} 
                  onChangeLoginState={this.updateLoginState}

                  clientLogoutInterval={this.state.clientLogoutInterval}
                  
                  render={
                    props => <DefaultLayout 
                                lang={actualLanguage} 
                                onChangeLang={(lng) => this.changeLang(lng)} 
                                userIsLoggedIn={this.state.userIsLoggedIn} 
                                onChangeLoginState={() => this.updateLoginState()}

                                clientLogoutInterval={this.state.clientLogoutInterval}
                                onChangeClientLogoutInterval={() => this.updateClientLogoutInterval()}
                                {...props}
                              />
                  } 
                />

              </Switch>
            </React.Suspense>
          </BrowserRouter>
        );
      }
      else{
        return (
          <BrowserRouter basename="/pzeweb">
              <React.Suspense fallback={loading()}>
                <Switch>
                  <EntryRoute 
                    exact
                    path="/login" 
                    name="Login"
                    render={props => 
                      <Login 
                        onChangeClientLogoutInterval={() => this.updateClientLogoutInterval()} 
                        workingMode={this.state.WorkingMode}
                        onChangeWorkingMode={(mode, history) => this.changeWorkingMode(mode, history) }
                        
                        isTerminalModeActive={this.state.isTerminalModeActive}
                        onChangeTerminalModeState={() => this.changeTerminalModeState()}
                        {...props} 
                      />
                    } 
                  />
                  <PrivateRoute 
                    path="/" 
                    name="PZE WebClient 3.0" 
                    userIsLoggedIn={this.state.userIsLoggedIn} 
                    onChangeLoginState={this.updateLoginState}
  
                    clientLogoutInterval={this.state.clientLogoutInterval}
                    
                    render={
                      props => <DefaultLayout 
                                  lang={actualLanguage} 
                                  onChangeLang={(lng) => this.changeLang(lng)} 
                                  userIsLoggedIn={this.state.userIsLoggedIn} 
                                  onChangeLoginState={() => this.updateLoginState()}
  
                                  clientLogoutInterval={this.state.clientLogoutInterval}
                                  onChangeClientLogoutInterval={() => this.updateClientLogoutInterval()}
                                  {...props}
                                />
                    } 
                  />
                </Switch>
              </React.Suspense>
          </BrowserRouter>
        );
      }
    }
  }
}

export default App;