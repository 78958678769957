import React , { Component }from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, Row} from 'reactstrap';
import { Trans} from 'react-i18next';
import { fadingSpinner } from './Spinners';

const propTypes = {
    open: PropTypes.bool.isRequired,
};

class LoadingModal extends Component{
    render(){
      return(
        <Modal centered isOpen={this.props.open}>
          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}}>
            <Row className="justify-content-center my-3">
              {fadingSpinner()}
            </Row> 
            <Row className="justify-content-center text-muted font-weight-light">
                <Trans i18nKey={this.props.msgKey} tOptions={this.props.msgParams}/>
            </Row>   
          </ModalHeader>
        </Modal>
      );
    }
  }

LoadingModal.propTypes = propTypes;

export default LoadingModal;